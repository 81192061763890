import { GetStaticPropsResult } from 'next';

import CdsLayout from '@components/Layout/CdsLayout';
import Layout from '@components/Layout/Layout';

import { NextPageWithLayout, PageProps } from './_app';

export type PukPortalIndexProps = {
  title: string;
};

const PukPortalIndexPage: NextPageWithLayout<
  PageProps<PukPortalIndexProps>
> = () => <></>;

PukPortalIndexPage.getLayout = function getLayout(page, props) {
  const PageLayout = props.config.server.site.basename.includes('gpp')
    ? Layout
    : CdsLayout;

  return (
    <PageLayout
      title={'SEARCH'}
      translateTitle={true}
      pageType={'Search Page'}
      slug={'/search'}
    >
      {page}
    </PageLayout>
  );
};

export async function getStaticProps(): Promise<
  GetStaticPropsResult<PageProps<PukPortalIndexProps>>
> {
  const { DataFetcher } = await import('@utils/server/DataFetcher');
  const { ConfigLoader } = await import('@utils/server/ConfigLoader');
  const configLoader = new ConfigLoader();
  const dataFetcher = new DataFetcher(configLoader);
  const dataProps = dataFetcher.getIndexProps();
  const configProps = dataFetcher.getConfigProps();

  if ('props' in dataProps) {
    return {
      ...dataProps,
      props: {
        ...dataProps.props,
        config: configProps,
      },
    };
  }

  return dataProps;
}

export default PukPortalIndexPage;
